<template>
    <div class="action-schedule">
        <template>
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icons/hello.png')" alt="" />
                    <span>약속이 임박했어요! 장소를 입력해주세요.</span>
                </div>
                <div class="message-content">
                    <div class="desc">
                        <span class="bold">{{ promise }}, {{ content.meet_place }}</span>
                        약속이 임박했습니다. 장소를 정해서 입력해주세요.
                    </div>
                    <div class="desc">
                        장소를 입력하지 않을 경우, 약속이 취소될 수 있고, 이용 제한 등의 페널티가 부여될 수 있습니다.
                    </div>
                    <div class="grey-box m-t-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div class="grey-box m-t-8 p-l-16">
                        <div class="title-grey">약속 내용</div>
                        <div class="content-grey">
                            <p class="m-b-4">{{ time }}</p>
                            <p>{{ content.meet_place }}에서 {{ meetType }}예정</p>
                        </div>
                    </div>
                    <div class="pink-box m-t-8 p-l-16 m-b-16">
                        <div class="title-pink">여성분이 꺼리는 음식</div>
                        <div class="content-pink">{{ foodPrefer }}</div>
                    </div>
                    <BottomButton :nonFixed="true" :label="'만남장소 입력하기'" @click="openModalMeetingPlaceInput" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleReservationUser',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        time() {
            const dateConfirmed = this.content.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        promise() {
            const dateConfirmed = this.content.date_confirmed
            return (
                this.$moment(dateConfirmed).format(`M월 D일 h시`) +
                (Number(this.$moment(dateConfirmed).format('mm')) > 0 ? this.$moment(dateConfirmed).format('mm분') : '')
            )
        },
        meetType() {
            const fixed = ['coffee', 'meal']

            return fixed.includes(this.content.meet_type_confirmed)
                ? this.$translate(this.content.meet_type_confirmed.toUpperCase())
                : '커피 또는 식사'
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
    },

    methods: {
        async openModalMeetingPlaceInput() {
            await this.$modal.custom({
                component: 'ModalMeetingPlaceInput',
                options: {
                    schedule_id: this.content.schedule_id,
                },
            })
            this.$store.dispatch('loadChat', {
                chatId: this.message.chat_id,
                force: true,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
}
.message-top {
    display: flex;
    align-items: flex-start;
}
.message-content {
    .bold {
        font-weight: 700;
    }
}
</style>
